import React from 'react';
import AnimatedText from './AnimatedText';

const YourComponent = () => {
  const words = ["Hydrid Cloud Solutions", "Multi-cloud Solutions", "Cloud Native Solutions"];

  return (
    <div className='w-[100%] h-[100px] text-white flex items-center justify-center'>
      <AnimatedText words={words} interval={6000} />
    </div>
  );
};

export default YourComponent;
