import { SiKubernetes } from "react-icons/si";
import { FaCloud } from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { GiOffshorePlatform } from "react-icons/gi";
import { GiServerRack } from "react-icons/gi";
import { GrDeploy } from "react-icons/gr";
import { FcDeployment } from "react-icons/fc";
import { MdOutlineSecurity } from "react-icons/md";
import { TbMapShare } from "react-icons/tb";
import { MdCloudSync } from "react-icons/md";


const services = [
    {
        name:'Google Cloud Platform',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<FaCloud />,
        button:'Maximize Cloud Advantage'
    },
    {
        name:'DevOps',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        icon:<FcDeployment />,
        button:'Deploy Seemlessly'
    },
    {
        name:'Kubernetes Consulting',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon: <SiKubernetes  color="#326DE6"/>,
        button:'Run k8s at Scale'
    },
    {
        name:'Cloud-native software Development',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<GrDeploy color="#00E887"/>,
        button:'Build and ship for you'
    },
    {
        name:'Geospatial Intelligence',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<TbMapShare  color="#C0A339"/>,
        button:'Location Intelligence'
    },
    {
        name:'Cloud Migrations',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<MdCloudSync />,
        button:'Migrate to Cloud'
    },
    {
        name:'Platform Engineering',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<GiPlatform color="#00E887"/>,
        button:'Efficient Developer Platforms'
    },
    {
        name:'Site Reliability Consulting',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<GiServerRack color="#C0A339"/>,
        button:'Reduce Downtime'
    },
    {
        name:'Security',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<MdOutlineSecurity />,
        button:'Threat Intelligence'
    },
    
]


export default services