import React, { useState, useEffect } from 'react';

const AnimatedText = ({ words, interval = 6000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, interval);

    return () => clearInterval(wordInterval);
  }, [words.length, interval]);

  return (
    <div className="text-2xl md:text-5xl font-bold animate-fadeFlip flex self-center">
      {words[index]}
    </div>
  );
};

export default AnimatedText;
