import React, { useEffect, useState } from 'react'
import Slider  from 'react-slick';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import YourComponent from './FadingText';

export const MainSlider = () => {
    const [categories,setCategories] = useState({});
    const [activeIndex,setActiveIndex] = useState(0)
    const [startFadeIn, setStartFadeIn] = useState(true);
    const [text,setText] = useState('Get started with Google Workspace products Today')

    const data = [
        {
            id:12,
            img:'Google-Workspace-products.png',
            component:<YourComponent/>,
            text:'Get started with Google Workspace products Today'
        },
        {
            id:13,
            img:'partners_logos.png',
            component:<YourComponent/>,
            text:'Your Hydrid & Multi-Cloud Environments Strategy partner'
        },
        {
            id:14,
            img:'Google_Cloud_Platform-Logo.wine.png',
            component:<YourComponent/>,
            text:'Seemless Infrastructure Modernization on Google Cloud Platform'
        },
        {
            id:7,
            img:'Google_Cloud_Platform-Logo.wine.png',
            component:<YourComponent/>,
            text:'RedaByte is a Google Cloud solutions provider for businesses across Africa'
        },
        {
            id:8,
            img:'VMware-Logo.wine.png',
            component:<YourComponent/>,
            text:'VMWare Tanzu Kubernetes solutions Experts'
        },
        {
            id:8,
            img:'Microsoft_Azure-Logo.wine.png',
            component:<YourComponent/>,
            text:'Microsoft Azure solutions Experts'
        },
        {
            id:9,
            img:'Amazon-Web-Services-1.png',
            component:<YourComponent/>,
            text:'AWS Experts for Cloud Native Solutions'
        },
] //useContext(rootDataContext);
    
    //const categories = cats.cats.data
    const settings = {
        beforeChange: (current,next) => {setActiveIndex(next); setText(null)},
        //beforeChange: (current,next) => setStartFadeIn(false),
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 8000,
        arrows:false,
        afterChange: (index) => {
            setStartFadeIn(false);
            setText(data[index].text) // Reset fade-in
           setTimeout(() => setStartFadeIn(true), 300)}
      };


      const settings2 = {
        dots: true,
        infinite: true,
        speed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 6000,
        fade: true
      };

    const setCategoryData = async () => {
        try{
            setCategories(data)
        }catch(err){

        }
    }

    useEffect(()=>{
        setCategoryData();
    },[]) 
    
    //WHen the parent component renders a second time, the context will trigger a rerender and update the value
    useEffect(()=>{
        console.log(data)
        console.log("categories set")
    },[data])
    return (
      /*<div className='w-full bg-blue flex relative'>*/
      <div className='z-20 h-3/4 w-[vw50] pt-4'>
        <div className=' h-[100%]  flex items-center justify-center'>
            <Slider className='z-20 first-line:h-[20%] w-[95%]' {...settings}>
            {data?data.map((d,index) => 
            <div className='flex flex-col w-[100%]'>
                
                <div className='flex' key={d.id}>
                   {/* <span className={` text-2xl font-bold md:text-6xl   text-white transition-opacity duration-1000 ${activeIndex === index ? 'animate-fadeIn' : 'opacity-0'}`}>
                        {d.text}
                    </span>*/}
                    <div className='h-[90%] w-[50%] flex text-center items-center justify-center'>
                        <span className={` text-2xl font-bold md:text-4xl   text-white transition-opacity duration-1000 ${activeIndex === index && text && startFadeIn? 'animate-fadeInFromBottom' : 'opacity-0'}`}>
                            {d.text}
                        </span>
                    </div>
                    <div className='h-[90%] w-[50%] flex text-center items-center justify-center'>
                        <img className='h-[45%] w-[50%]' src={d.img}>
                        </img>
                    </div>
                </div>
            </div>
                
                
                ):""}
                {/*data?data.map((d) => 
                <div className='flex-col shadow' key={d.id}>
                    <div className='h-[90%] w-[90%] flex items-center justify-center'>
                        <img className='sm:h-[35%] sm:w-[98%]' src={d.img}>
                        </img>
                        {<p className='absolute top-3/4 text-white'>{d.capt}</p>}
                    </div>
                </div>
                
                ):""*/}
            </Slider>
        </div>
      </div>
    )
  }

